<script lang="ts">
    import ValidityMessage from "./ValidityMessage.svelte";
    import GifMoji from "./GifMoji.svelte";
    import { AnswerResponse } from "./AnswerResponse";
    import { QuizStats } from "./QuizStats";
    import { preferencesStore } from "./preferencesStore";
    import { DisplayMode } from "./DisplayMode";
    export let submission: AnswerResponse;
    export let quizStats: QuizStats;
    export let fact: string;

    let showAnswer: boolean = false;
</script>

<div
    class="full-height flex flex-col justify-center items-center text-center gap-10 w-full"
>
    <div class="flex flex-col gap-4 items-center text-center w-full">
        {#if $preferencesStore.displayMode === DisplayMode.Minimal}
            <ValidityMessage
                {quizStats}
                answer={{ correct: !!submission?.correct }}
                class="mt-10 w-4/5"
            />
            <p class="w-4/5 text-pretty">
                {submission.response}
            </p>
        {:else}
            {#if !showAnswer}
                <GifMoji correct={submission.correct} />
            {/if}
            <ValidityMessage
                {quizStats}
                answer={{ correct: !!submission?.correct }}
                class="mt-10 w-4/5"
            />
            {#if showAnswer}
                <p class="w-4/5 text-pretty">
                    {submission.response}
                </p>
                <p class="font-bold mt-10 w-4/5 text-pretty">Fun Fact:</p>
                <p class="w-4/5 text-pretty">
                    {fact}
                </p>
            {/if}
        {/if}
    </div>
    <div class="flex flex-col justify-center gap-4">
        {#if !showAnswer && $preferencesStore.displayMode === DisplayMode.Fun}
            <button
                class="btn btn-primary"
                on:click={() => (showAnswer = true)}
            >
                Tell me why
            </button>
        {/if}
    </div>
</div>

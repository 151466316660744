<script lang="ts">
    import FunToggle from "./FunToggle.svelte";
import GifMoji from "./GifMoji.svelte";
</script>

<div class="w-full flex flex-col py-4 gap-4 full-height items-center">
    <FunToggle></FunToggle>
    <div class="flex flex-col gap-4 items-center full-height w-full justify-center">
        <GifMoji correct={false}/>
        <p class="font-bold text-center w-4/5"><slot/></p>
    </div>
</div>
export const questionSelectionSet = [
  "topic.reteyner.name",
  "topic.reteyner.leaderboard",
  "topic.reteyner.organisation.maximumBonusQuizzes",
  "text",
  "random",
  "answers.text",
  "answers.response",
  "answers.correct",
  "answers.id",
  "topic.text",
] as const;

<script lang="ts">
    import { derived, writable, Writable } from "svelte/store";
    import { Test } from "../question-picker";
    import { ReteynSchema } from "../schema";
    import { QuizStats } from "./QuizStats";
    import { toCorrectCount } from "./toCorrectCount";
    import { createTable, DataBodyRow } from "svelte-headless-table";
    import { addSortBy, addColumnOrder } from "svelte-headless-table/plugins";
    import { Table } from "../reteyn-components/index.js";
    import orderBy from "lodash/orderBy";
    import { LeaderboardRow } from "./LeaderboardRow";

    const dataStore: Writable<LeaderboardRow[]> = writable([]);
    export let data: ReteynSchema["leaderboard"]["returnType"];
    export let quizStats: QuizStats;
    export let test: Test;

    const numberFormatter = new Intl.NumberFormat(navigator.language);

    $: $dataStore = orderBy(
        [
            ...data.map((row) => ({ ...row, current: false })),
            {
                name: "You",
                current: true,
                correct: toCorrectCount(quizStats, test),
            },
        ],
        ["correct", "name"],
        ["desc", "asc"],
    )
        .map((row, _, array) => ({
            ...row,
            rank: array.map((r) => r.correct).indexOf(row.correct),
        }))
        .filter((row, index) => index < 10 || row.current);

    const table = createTable(dataStore, {
        colOrder: addColumnOrder(),
        sort: addSortBy(),

        highlightCurrent: () => ({
            pluginState: {},
            hooks: {
                "tbody.tr": (row) => ({
                    attrs: derived(row.attrs(), (attrs) => ({
                        ...attrs,
                        class: row.isData()
                            ? (row as DataBodyRow<LeaderboardRow>).original
                                  .current
                                ? "bg-base-200"
                                : ""
                            : "",
                    })),
                }),
            },
        }),
    });
    const plugins = {
        sort: {
          disable: true,
        },
      },
    const columns = table.createColumns([
        table.column({
            header: "Rank",
            id: "rank",
            accessor: (row) => numberFormatter.format(row.rank + 1),
            plugins
        }),
        table.column({
            header: "Name",
            id: "name",
            accessor: (row) => row.name,
            plugins
        }),
        table.column({
            header: "Mastery",
            id: "correct",
            accessor: (row) =>
                Math.round((100 * row.correct) / quizStats.questions) + "%",
            plugins
        }),
    ]);
    const viewModel = table.createViewModel(columns);
</script>

<Table {viewModel} />

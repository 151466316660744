import { persisted } from "svelte-persisted-store";
import { Writable } from "svelte/store";
import { DisplayMode } from "./DisplayMode";

const initialValue = {
  displayMode: DisplayMode.Fun,
};
export const preferencesStore = persisted("preferences", initialValue, {
  beforeRead: (v) =>
    v.displayMode && Object.values(DisplayMode).includes(v.displayMode)
      ? v
      : initialValue,
}) as Writable<{ displayMode: DisplayMode }>;

import { Test } from "../question-picker";
import { QuizStats } from "./QuizStats";

export function toCorrectCount(quizStats: QuizStats, test: Test): number {
  return (
    quizStats.distinctCorrectSubmissions +
    (quizStats.previousAnswerCorrect && !test.submission?.answer.correct
      ? -1
      : !quizStats.previousAnswerCorrect && test.submission?.answer.correct
        ? 1
        : 0)
  );
}

<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import QuestionComponent from "./Question.svelte";
    import Response from "./Response.svelte";
    import ReteynerMastery from "./ReteynerMastery.svelte";
    import { QuizStats } from "./QuizStats";
    import { Question } from "./Question";
    import { Test } from "../question-picker";
    import FunToggle from "./FunToggle.svelte";
    import { ReteynSchema } from "../schema";
    import Leaderboard from "./Leaderboard.svelte";

    const dispatch = createEventDispatcher<{
        answer: Question["answers"][number];
    }>();

    export let question: Question;
    export let quizStats: QuizStats;
    export let test: Test;
    export let fact: string;
    export let leaderboardData: Promise<
        ReteynSchema["leaderboard"]["returnType"]
    >;

    let leaderboardDialog: HTMLDialogElement;
</script>

<div class="full-height my-4 w-full flex flex-col gap-4">
    <div class="w-full px-4">
        <ReteynerMastery {question} {quizStats} {test} />
    </div>
    <div class="full-height flex flex-col justify-center items-center w-full">
        {#if test.submission}
            <div class="w-full flex flex-row justify-between px-4">
                <FunToggle />
                {#await leaderboardData then data}
                    {#if data.length}
                        <button
                            class="btn btn-xs"
                            on:click={() => leaderboardDialog.showModal()}
                            >Leaderboard</button
                        >
                        <dialog class="modal" bind:this={leaderboardDialog}>
                            <form method="dialog" class="modal-backdrop">
                                <button>close</button>
                            </form>
                            <div class="modal-box relative px-0 py-0">
                                <div class="flex flex-col gap-2 pb-2">
                                    <div
                                        class="flex flex-row px-2 py-2"
                                    >
                                        <div class="w-full px-2 flex flex-col justify-center">
                                            <p class="font-bold">
                                                {question.topic.reteyner.name} leaderboard
                                            </p>
                                        </div>
                                        <button
                                            class="btn btn-sm btn-circle"
                                            on:click={() =>
                                                leaderboardDialog.close()}
                                            >✕</button
                                        >
                                    </div>
                                    <Leaderboard {data} {quizStats} {test} />
                                </div>
                            </div>
                        </dialog>
                    {/if}
                {/await}
            </div>
            {@const submission = question.answers.find(
                (a) => a.id === test.submission.answer.id,
            )}
            {#if submission}
                <div
                    class="full-height flex flex-col justify-center items-center w-full gap-4"
                >
                    <Response {submission} {quizStats} {fact} />
                </div>
            {/if}
        {:else}
            <QuestionComponent
                {question}
                on:answer={(event) => dispatch("answer", event.detail)}
            />
        {/if}
    </div>
</div>

<script lang="ts">
    import { History, Test } from "../question-picker/index.js";
    import Quiz from "./Quiz.svelte";
    import { QuizApi } from "./QuizApi";
    import TruthyLoader from "./TruthyLoader.svelte";
    import { QuizStatsResolver } from "./QuizStatsResolver";
    import { pickRandom } from "../dao/index.js";
    import { funFacts } from "../dao/funFacts.js";
    import { ErrorMessage } from "../schema/ErrorMessage.js";
    import { Writable } from "svelte/store";
    import { ReteynSchema } from "../schema/index.js";

    export let api: QuizApi;
    export let testId: string;
    export let quizStatsResolver: QuizStatsResolver;
    let historyStore: Writable<Promise<History[]>>;

    async function toTest(dataPromise: Promise<History[]>, testId: string): Promise<Test> {
        const history = await dataPromise;
        const test = api.toTest(history, testId);
        if (!test) {
            throw new Error("Not found");
        }
        if (api.hasExpired(test)) {
            throw new Error(ErrorMessage.Expired);
        }
        return test;
    }

    $: historyStore = api.toHistoryStore({testId});
    
</script>
{#if testId}
    {@const testPromise = toTest($historyStore, testId)}
    <TruthyLoader promise={testPromise}>
        {#await Promise.all([$historyStore, testPromise]) then [history, test]}
            {@const fact = pickRandom(funFacts)}
            {@const questionPromise = api.question({questionId: test.questionId})}
            {@const quizStats = quizStatsResolver.toQuizStats(history, {testId})}
            {@const leaderboardData = questionPromise.then(q => q.topic.reteyner.leaderboard ? api.leaderboard({testId}) : [])}
            <TruthyLoader promise={questionPromise}>
                {#await questionPromise then question}
                <Quiz
                    {fact}
                    {leaderboardData}
                    {question}
                    {quizStats}
                    {test}
                    on:answer={(event) => api.submit({ testId, answerId: event.detail.id })}
                />
                {/await}
            </TruthyLoader>
        {/await}
    </TruthyLoader>
{/if}




<script lang="ts">
    import { DisplayMode } from "./DisplayMode";
    import { preferencesStore } from "./preferencesStore";
</script>

<div class="join">
    <input
        class="join-item btn btn-xs"
        type="radio"
        name="displayMode"
        aria-label="Fun"
        value={DisplayMode.Fun}
        bind:group={$preferencesStore.displayMode}
    />
    <input
        class="join-item btn btn-xs"
        type="radio"
        name="displayMode"
        aria-label="Minimal"
        value={DisplayMode.Minimal}
        bind:group={$preferencesStore.displayMode}
    />
</div>
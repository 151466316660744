<script lang="ts">
    import { pickRandom } from "../dao";
    import { gifs } from "../dao/gifs";
    import { DisplayMode } from "./DisplayMode";
    import { preferencesStore } from "./preferencesStore";
    import Gif from "./Gif.svelte";

    export let correct: boolean;

    $: gif = pickRandom(gifs.filter((g) => g.correct === correct));
</script>

<div class="flex w-full justify-center flex-col items-center relative">
    <div class="w-full flex justify-center">
        {#if $preferencesStore.displayMode === DisplayMode.Fun}
            <div class="w-full h-64 bg-base-content">
                <Gif {gif} />
            </div>
        {/if}
    </div>
</div>
